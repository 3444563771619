import { useState, useEffect } from 'react';

function ThemeSwitcher(props) {
  const themes = [
    { name: 'theme-dark', logoColor: 'white' },
    { name: 'theme-grayscale', logoColor: 'white' },
    { name: 'theme-mirage', logoColor: 'white' },
    { name: 'theme-twilight', logoColor: 'white' },
    { name: 'theme-darkness', logoColor: 'white' },
    { name: 'theme-pizelex', logoColor: 'white' },
    { name: 'theme-metapolis', logoColor: 'white' },
    { name: 'theme-kyoo-tah', logoColor: 'white' },
  ];

  const [currentThemeIndex, setCurrentThemeIndex] = useState(() => {
    const savedTheme = localStorage.getItem('themeIndex');
    return savedTheme ? parseInt(savedTheme) : 0;
  });

  const updateLogo = () => {
    const currentTheme = themes[currentThemeIndex];
    const logoColor = currentTheme.logoColor === 'white' ? props.logoLight : props.logoDark;
    props.setLogo(logoColor);
  };

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add(themes[currentThemeIndex].name);

    updateLogo();

    localStorage.setItem('themeIndex', currentThemeIndex);
  }, [currentThemeIndex]);

  function toggleTheme() {
    setCurrentThemeIndex((prevIndex) => (prevIndex + 1) % themes.length);
  }

  return (
    <div className="m-auto rounded-full border border-[--text-color] h-6 w-6 bg-gradient-to-r from-[--gradient-first] to-[--gradient-second] text-[--text-color] font-['Roboto']">
      <button id="theme-button" title="theme button" className='h-full w-full' onClick={toggleTheme}>
      </button>
    </div>
  );
}

export default ThemeSwitcher;
